/* Style for the green headline */
.green-headline {
    color: #22c55e; /* Tailwind green-500 */
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: -40px;
    text-align: center; /* Center the headline */
}

/* Container for the pin input */
.pin-input-container {
    display: flex;
    justify-content: center;
}

/* Single input style for the pin */
.pin-input {
    width: 150px; /* Full length for typing all 6 digits */
    height: 40px; /* Adjust height */
    font-size: 20px; /* Larger font for better visibility */
    text-align: center; /* Center the text inside the input */
    border: none; /* Remove border */
    border-bottom: 2px solid #ccc; /* Line instead of box */
    background-color: #ffffff;
    outline: none;
    padding: 0;
    letter-spacing: 10px; /* Spacing between digits */
}

/* Placeholder style to mimic dots */
.pin-input::placeholder {
    color: #ccc; /* Light gray for placeholder asterisks */
}

/* Focus style */
.pin-input:focus {
    border-bottom-color: #25d366; /* Change line color on focus */
    box-shadow: 0 2px 5px rgba(37, 211, 102, 0.5); /* Add subtle shadow on focus */
}

/* Green text and customization */
.green-text {
    color: #22c55e; /* Tailwind green-500 */
    font-size: 13px; /* Default font size */
    text-align: center; /* Center the text */
    font-weight: bold;
}

.message-text {
    font-size: 14px; /* Adjust this value as needed */
    line-height: 1.5; /* Optional: controls the spacing between lines */
}

/* Assuming this is in your CSS file */
body {
    background-color: white; /* Change to white or your desired color */
}

.Conts {
    background-color: white; /* Ensure your container is white */
}
